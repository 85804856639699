import React from 'react';
import { Link } from 'react-router-dom';
import Socials from './socials';
import alert from '../assets/img/alert.png';
import gardenlogo from '../assets/updated-svg/logo.svg';

export interface AppFooterProps { }

const AppFooter: React.SFC<AppFooterProps> = () => {
  return (
    <React.Fragment>
      <footer className="bg-dark" style={{ backgroundColor: "F0F0F0" }}>
        <div className="container">
          <div className="space-top-2 space-bottom-1 space-bottom-lg-2">
            <div className="row justify-content-lg-between">
              <div className="col-md-3">
                <div className="mb-4">
                  <Link to="/" aria-label="Front">
                    <img
                      src={gardenlogo}
                      style={{ width: "7rem" }}
                      className="img-fluid"
                      alt="Logo"
                    />
                  </Link>
                </div>

                <ul className="nav nav-sm nav-x-0 nav-white flex-column">
                  <li className="nav-item">
                    <h6 className="m-0">Headquarters</h6>
                    <div className="nav-link media">
                      <span className="media">
                        {/* <span className="fas fa-location-arrow mt-1 mr-2"></span> */}
                        <span className="media-body">
                          Dubai Digital Park, A5 Building DTEC,<br />
                          Dubai Silicon Oasis, Dubai, UAE.<br />
                        </span>
                      </span>
                    </div>
                  </li>

                  <li className="nav-item">
                    <h6 className="m-0">Nigeria Office</h6>
                    <div className="nav-link media">
                      <span className="media">
                        {/* <span className="fas fa-location-arrow mt-1 mr-2"></span> */}
                        <span className="media-body">
                          Vibranium Valley <br />
                          42, Local Airport Road, Ikeja <br />
                          Lagos
                        </span>
                      </span>
                    </div>
                  </li>

                  <li className="nav-item">
                    <h6 className="m-0">North America Office</h6>
                    <div className="nav-link media">
                      <span className="media">
                        {/* <span className="fas fa-location-arrow mt-1 mr-2"></span> */}
                        <span className="media-body">
                          6611 W 14th Street, Austin, 78701,<br />
                          Texas, U.S.
                        </span>
                      </span>
                    </div>
                  </li>

                  <li className="nav-item">
                    <h6 className="m-0">Enquiries</h6>
                    <div className="nav-link">
                      <div className="media">
                        <a className="media" href="tel:+1 512 879 6557">
                          <div className="media">
                            <span className="media-body">
                              +1 512 879 6557
                            </span>
                          </div>
                        </a>
                      </div>
                      <div className="media">
                        <a className="media" href="tel: +234 (0908) 748-2267">
                          <div className="media">
                            <span className="media-body">
                              +234 (0908) 748-2267
                            </span>
                          </div>
                        </a>
                      </div>
                    </div>
                  </li>
                </ul>
                <br />
                <br />
              </div>

              <div className="col-md-3">
                <h6>PRODUCTS</h6>

                <ul className="nav nav-sm nav-x-0 nav-white flex-column">
                  <li className="nav-item">
                    <a
                      className="nav-link nav-footer"
                      target="_blank"
                      href="http://vigilearn.com/"
                      rel="noreferrer"
                    >
                      VigiLearn
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link nav-footer"
                      target="_blank"
                      href="http://bursery.com/"
                      rel="noreferrer"
                    >
                      Bursery
                    </a>
                  </li>
                </ul>

                <br />
                <br />
              </div>

              <div className="col-md-3 ">
                <h6>COMPANY</h6>

                <ul className="nav nav-sm nav-x-0 nav-white flex-column">
                  <li className="nav-item">
                    <Link className="nav-link nav-footer" to="/about">
                      About us
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link nav-footer" to="/blog">
                      Blog
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link nav-footer" to="/partners">
                      Partners
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link nav-footer" to="/privacy-policy">
                      Privacy policy
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link nav-footer"
                      to="/security-awareness"
                    >
                      Security Awareness
                    </Link>
                  </li>
                </ul>
                <br />
                <br />
              </div>

              <div className="col-md-3 ">
                <h6>CONTACT US</h6>
                <ul className="nav nav-sm nav-x-0 nav-white flex-column">
                  {/* <li className="nav-item">
                    <a className="nav-link media" href="tel:08080523171">
                      <span className="media">
                        <span className="media-body">
                          +234 [0] 805 584 5376
                        </span>
                      </span>
                    </a>
                  </li>

                  <li className="nav-item">
                    <a className="nav-link media" href="tel:08080523171">
                      <span className="media">
                        <span className="media-body">
                          +234 [0] 703 574 7526
                        </span>
                      </span>
                    </a>
                  </li>

                  <li className="nav-item">
                    <a className="nav-link media" href="tel:+971 4 311 6041">
                      <span className="media">
                        <span className="media-body">+971 [4] 311 6041</span>
                      </span>
                    </a>
                  </li> */}

                  <li className='nav-item'>
                    <a className='nav-link media' href='mailto:clientservice@edutech.global'>
                      <span className='media'>
                        {/* <span className="fas fa-envelope-open mt-1 mr-2"></span> */}
                        <span className='media-body'>clientservice@edutech.global</span>
                      </span>
                    </a>
                  </li>
                  <a className='nav-link media' href='tel:1-062-109-9222'>
                    <span className='media'>
                      <span className='media-body'>Follow Us</span>
                    </span>
                  </a>
                  <Socials></Socials>
                </ul>

                <br />
                <br />
              </div>
            </div>
          </div>

          <hr className='opacity-xs my-0' />
          <div>
            <p className='d-none d-lg-block' style={{ padding: '2rem 0', color: '#051A52' }}>
              © {new Date().getFullYear()} Edutech - All Rights Reserved.
            </p>

            <p className='d-lg-none' style={{ padding: '2rem 0', color: '#051A52' }}>
              © {new Date().getFullYear()} Edutech - <br />
              All Rights Reserved.
            </p>
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
};

export default AppFooter;

import React, { FC } from 'react';
import heroImage from '../assets/img/pexels-andrea-piacquadio-3786763.jpg';
import programImage from '../assets/img/program.jpg';
import flexibaleImage from '../assets/img/flexibale.jpg';
import graduateImage from '../assets/img/graduate.jpg';
import fastTrack from '../assets/img/student-working-from-home.jpg';

const BabcockCampaign: FC = () => {
	return (
		<main>
			<section className='container' style={{ marginTop: '100px' }}>
				<div className='row justify-content text-center'>
					<div style={{ marginTop: '40px' }} className='col-12 col-lg-6 order-last order-lg-1'>
						<h1 style={{ fontSize: '23px' }}>Master the languages of money and business management with an Accounting degree from Babcock University.</h1>
						<p style={{ fontSize: '12px' }}>Here is your chance to earn an accounting degree, no matter where you are.</p>
						<p style={{ fontSize: '12px' }}>
							Powered by EduTech in partnership with Babcock University, the highly ranked programme delivers the knowledge and skills you need to advance your
							career whenever and wherever it’s convenient for you.
						</p>
						<a href='https://applycodel.babcock.edu.ng/'>
							<button type='button' className='mt-2 btn btn-dark'>
								APPLY NOW
							</button>
						</a>
					</div>
					<div className='col-12 col-lg-6 order-sm-frist order-lg-12'>
						<img src={heroImage} style={{ height: '100%', width: '100%' }} alt='hero image' />
					</div>
				</div>
			</section>
			<section
				style={{
					backgroundColor: '#D7DCE0',
					marginTop: '50px',
					paddingTop: '50px',
					paddingBottom: '50px',
				}}>
				<div className='container'>
					<div className='row'>
						<div className='col-12 col-lg-6'>
							<img src={programImage} style={{ height: '100%', width: '100%' }} alt='hero image' />
						</div>
						<div className='col-12 col-lg-6'>
							<div className='text-center container' style={{ paddingTop: '80px' }}>
								<h1 style={{ fontSize: '30px' }}>Program</h1>
							</div>
							<div className='text-center'>
								<h1 style={{ fontSize: '20px', marginTop: '20px' }}>Undergraduate Programmes </h1>
								<p style={{ fontSize: '12px' }}>BSc. Accounting</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section
				style={{
					marginTop: '50px',
				}}
				className='container'>
				<div className='text-center container' style={{ paddingTop: '60px', paddingBottom: '30px' }}>
					<h1 style={{ fontSize: '30px' }}>Why Babcock? </h1>
				</div>
				<div className='row'>
					<div className='col-12 col-lg-6' style={{ marginBottom: '50px' }}>
						<div style={{ height: '100%' }} className='card border-0'>
							<img src={flexibaleImage} className='card-img-top' alt='...' />
							<div className='card-body'>
								<h5 className='card-title'>Flexible Learning</h5>
								<p style={{ fontSize: '12px' }} className='card-text'>
									With our online learning platform, you get to study at your pace, whenever and wherever it’s convenient for you.
								</p>
							</div>
						</div>
					</div>

					<div className='col-12 col-lg-6' style={{ marginBottom: '50px' }}>
						<div style={{ height: '100%' }} className='card border-0'>
							<img src={graduateImage} className='card-img-top' alt='...' />
							<div className='card-body'>
								<h5 className='card-title'>Same degree as on-campus</h5>
								<p style={{ fontSize: '12px' }} className='card-text'>
									You earn the same degree as those who studied on campus. No differentiation in degree.
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className='text-center mb-5' style={{ marginTop: '30px' }}>
					<h4>Ready to take your life and career to the next level</h4>

					<p style={{ fontSize: '12px' }}>Don’t take our words for it, here’s what those who have experienced us have to say:</p>
					<p style={{ fontSize: '12px' }}>
						“Taking online classes with Babcock has been a major benefit to me. As I work toward completing my degree, what I enjoy the most is the flexibility
						which has allowed me to be home for my family and work.” _ Blessing Chuma [Accounting Student].
					</p>
					<p style={{ fontSize: '12px' }}>A degree in accounting will set you up with a flexible and potentially lucrative career.</p>

					<a href='https://applycodel.babcock.edu.ng/'>
						<button type='button' className='mt-2 btn btn-dark mt-5 mb-5'>
							APPLY NOW
						</button>
					</a>
				</div>
				<p style={{ fontSize: '12px' }}>
					EduTech is a technology company focused on bridging the gap between demand for and access to quality education by partnering with local and
					international institutions to make their programs accessible to students. We are able to achieve this through our e-learning platform, study abroad
					options and student advisory services for prospects to these institutions.{' '}
				</p>
				<hr />
			</section>
		</main>
	);
};

export default BabcockCampaign;

import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AppHomePage from "./pages/home-page";
import AppFooter from "./components/footer";
import AppHeader from "./components/header";
import AppAboutUsPage from "./pages/about-us-page";
import AppContactUsPage from "./pages/contact-us-page";
import Referral from "./pages/referral";
import AppNotFoundPage from "./pages/not-found-page";
import AppBlog from "./pages/blog";
import AppPartners from "./pages/partners";
import AppVigiLearnPage from "./pages/vigi-learn";
import ScrollToTop from "./helpers/scrollToTheTop";
import AppBlogSingle from "./pages/blog-single";
import AppEduCollectPage from "./pages/edu-collect";
import CookiePolicyPage from "./pages/cookie-policy";
import SecurityAwarenessPage from "./pages/security-awareness";
import AbuCampaign from "./pages/abu-campaign";
import BabcockCampaign from "./pages/babcock-campaign";

import Cookie from "./components/Cookie";
// import ToastAlert from "./components/Toast"

import { ToastProvider } from "react-toast-notifications";
import PrivacyPolicyPage from "./pages/privacy-policy";

export interface AppProps { }

const App: React.SFC<AppProps> = () => {
  return (
    <Router>
      <ScrollToTop />

      <div className="App">
        <ToastProvider placement="top-center" transitionDuration={10} >
          <AppHeader></AppHeader>
          <Switch>
            <Route path="/" exact component={AppHomePage} />
            <Route path="/partners" component={AppPartners} />
            <Route path="/blog" component={AppBlog} />
            <Route path="/blog-details" component={AppBlogSingle} />

            <Route path="/about" component={AppAboutUsPage} />
            <Route path="/contact" component={AppContactUsPage} />
            <Route path="/referral" component={Referral} />
            <Route path="/vigi-learn" component={AppVigiLearnPage} />
            <Route path="/edu-collect" component={AppEduCollectPage} />
            <Route path="/privacy-policy" component={PrivacyPolicyPage} />
            <Route path="/cookie-policy" component={CookiePolicyPage} />
            <Route path="/security-awareness" component={SecurityAwarenessPage} />
            <Route path="/abu-campaign" component={AbuCampaign} />
            <Route path="/babcock-campaign" component={BabcockCampaign} />
            <Route path="" component={AppNotFoundPage} />
          </Switch>
          <AppFooter></AppFooter>
          <Cookie />
        </ToastProvider>
      </div>
    </Router>
  );
};

export default App;

import { Link } from 'react-router-dom';
// @ts-ignore
import Fade from 'react-reveal/Fade';
// @ts-ignore
import Zoom from 'react-reveal/Zoom';
import Cardholder from '../assets/updated-svg/Cardholder.svg';
import Wallets from '../assets/updated-svg/Wallets.svg';
import Prohib from '../assets/updated-svg/Prohib.svg';

import '../assets/css/edupage.css';
import eduimage1 from '../assets/img/eduimage1.jpg';
import eduimage2 from '../assets/img/eduimage2.jpg';
import eduimage3 from '../assets/img/eduimage3.jpg';

export interface AppEduCollectPageProps {}

const AppEduCollectPage: React.FC<AppEduCollectPageProps> = () => {
	return (
		<>
			<Zoom>
				<div className='d-lg-flex position-relative eduhero'>
					<div className='container d-lg-flex align-items-lg-center space-3 min-vh-lg-100'>
						<Fade left>
							<div
								className='col-lg-6 card p-4'
								style={{
									background: 'rgba(24, 24, 24, 0.25)',
									border: 'none',
									padding: '20px 12px',
									height: 'auto',
								}}>
								<div className='mb-5 mt-11'>
									<h4 style={{ color: '#ffff' }}>BURSERY</h4>
									<div style={{ width: 'auto' }}>
										<h1 className='display-4  ' style={{ color: '#ffff', fontSize: '36px' }}>
											Quick Access To Affordable Education Finance
										</h1>
									</div>

									<p className='lead' style={{ color: '#ffff' }}>
										We are a technology company focused on bridging the gap between demand for and access to quality education through the process of decentralizing the
										traditional method of learning.
									</p>
								</div>
								<div>
									<a className='btn btn-primary btn-background-color btn-wide transition-3d-hover' href='http://www.bursery.com' target='_blank' rel='noreferrer'>
										Learn More
									</a>
								</div>
							</div>
						</Fade>
					</div>
				</div>
			</Zoom>

			<br />

			<div className='container'>
				<div className='row'>
					<div className='col-md-12'>
						<h3 style={{ textAlign: 'center', color: '#041644' }} className='animated slideInUp'>
							KEY BENEFITS
						</h3>
						<Zoom>
							<div className='container '>
								<div className='row mx-n2 mx-lg-n3'>
									<div className='col-sm-6 col-lg-4 px-2 px-lg-3 mb-3 mb-lg-0 mt-3  card-hover transition-3d-hover'>
										<div className='card-center h-100 text-center pt-4'>
											<div className='card-icon'>
												<img src={Prohib} alt='' />
											</div>
											<h3 style={{ color: 'red' }} className='card-headers text-center'>
												Zero Collateral
											</h3>
											<div className='card-body'>
												With Bursery, you aren’t required to provide a collateral for your loan. By just filing and uploading certain information, you can secure a loan for
												your educational needs.
											</div>
										</div>
									</div>
									<div className='col-sm-6 col-lg-4 px-2 px-lg-3 mb-3 mb-lg-0 mt-3  card-hover transition-3d-hover'>
										<div className='card-center h-100 text-center pt-4'>
											<div className='card-icon'>
												<img src={Cardholder} alt='' />
											</div>
											<h3 className='card-headers text-center'>Flexible Repayment</h3>
											<div className='card-body'>
												Reducing the burden of repaying a loan at once is one unique benefit Bursery has put in place. With its repayment option, you can spread the loan
												capital across a timeline.
											</div>
										</div>
									</div>

									<div className='col-sm-6 col-lg-4 px-2 px-lg-3 mb-3 mb-lg-0 mt-3  card-hover transition-3d-hover'>
										<div className='card-center h-100 text-center pt-4'>
											<div className='card-icon'>
												<img src={Wallets} alt='' />
											</div>
											<h3 className='card-headers text-center'>Multiple Loan Providers</h3>
											<div className='card-body'>
												Bursery being a gateway for you to access loan to meet your educational needs, gives you the benefit of choosing the provider that satisfies you with
												their terms and conditions.
											</div>
										</div>
									</div>
								</div>
							</div>
						</Zoom>
					</div>
				</div>
			</div>
			<br />
			<br />
			<div className='space-3 laptopbg  min-vh-lg-100'>
				<div className=' container'>
					<div className='row'>
						<div className='offset-sm-1 col-sm-10'>
							<div className='embed-responsive embed-responsive-16by9'>
								<iframe src='https://player.vimeo.com/video/549196090' frameBorder='0' allow='autoplay; encrypted-media' allowFullScreen title='video' />
							</div>
						</div>
					</div>
				</div>
			</div>

			<Fade left>
				<div className='space-2' style={{ background: '#E8EFFD', width: '100%' }}>
					<div className='session-four container'>
						<div className='mx-md-auto'>
							<div className='text-center'>
								<h2 style={{ color: '#03073F', fontSize: '32px' }}>Why Should I Use Bursery?</h2>
							</div>

							<br />
							<div className='row'>
								<div className='col-sm-4 mt-4'>
									<div className='card' style={{ height: 'auto' }}>
										<div className=''>
											<img className=' img-fluid' src={eduimage1} alt='none ' />
										</div>

										<div className='p-4'>
											<span style={{ color: '#0E347E', paddingBottom: '15px' }} className='d-block h3 mb-0 '>
												For Academic <br></br>
												Purposes
											</span>

											<p className=' text-body'>
												Education at this early stage is crucial and funding should not be a barrier. Let us ease your burden by ensuring you can give your wards the quality
												education they need.
											</p>
											<br />

											{/* <Link
                        to="/vigi-learn"
                        className="btn btn-primary btn-wide transition-3d-hover col-lg-12"
                      >
                        Learn More
                      </Link> */}
										</div>
									</div>
								</div>
								<div className='col-sm-4 mt-4'>
									<div className='card' style={{ height: 'auto' }}>
										<div className=''>
											<img className=' img-fluid' src={eduimage2} alt='none ' />
										</div>

										<div className='p-4'>
											<span style={{ color: '#0E347E', paddingBottom: '15px' }} className='d-block h3 mb-0 '>
												For Professional <br></br>Trainings
											</span>

											<p className=' text-body'>
												You shouldn’t have to deal with the stress of completing your fees at once, so we’re here to ease that for you with easily accessible loans to aid
												your academic pursuits.
											</p>
											<br />

											{/* <Link
                        to="/vigi-learn"
                        className="btn btn-primary btn-wide transition-3d-hover col-lg-12"
                      >
                        Learn More
                      </Link> */}
										</div>
									</div>
								</div>
								<div className='col-sm-4 mt-4'>
									<div className='card' style={{ height: 'auto' }}>
										<div className=''>
											<img className='img-fluid' src={eduimage3} alt='none ' />
										</div>

										<div className='p-4'>
											<span style={{ color: '#0E347E', paddingBottom: '15px' }} className='d-block h3 mb-0'>
												For Educational Establishments
											</span>

											<p className=' text-body'>
												Striving to further your academic achievements shouldn’t be limited by financial resources, so we’re here to bridge that gap for you by offering you
												collateral-free financing to keep your dream alive.
											</p>

											{/* <Link
                        to="/vigi-learn"
                        className="btn btn-primary btn-wide transition-3d-hover col-lg-12"
                      >
                        Learn More
                      </Link> */}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Fade>
		</>
	);
};

export default AppEduCollectPage;

// import 'bootstrap/dist/css/bootstrap.min.css';
import $ from "jquery";
import Popper from "popper.js";
import "bootstrap/dist/js/bootstrap.bundle.min";

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import "animate.css/animate.min.css";
import ReactGA from "react-ga";
import { EnvVariables } from "./env/Env";

import TagManager from "react-gtm-module";

const trackingId =
  EnvVariables?.REACT_APP_GOOGLE_ANALYTICS_ID ||
  process.env.REACT_APP_GOOGLE_ANALYTICS_ID ||
  "";

const tagManagerArgs = {
  gtmId: trackingId,
};

TagManager.initialize(tagManagerArgs);

ReactGA.initialize(trackingId);
ReactGA.pageview(window.location.pathname + window.location.search);

ReactDOM.render(
  // <React.StrictMode>
  <App />,
  // </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

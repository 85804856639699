import React from "react";
import { Link } from "react-router-dom";

export interface SecurityAwarenessPageProps {}

const SecurityAwarenessPage: React.SFC<SecurityAwarenessPageProps> = () => {
  return (
    <main>
      <div className="container privacy-wrapper space-top-3 space-bottom-2">
        <h1 className="text-center">Security Awareness</h1>

        <h2 className="space-top-1">Perface</h2>
        <p>
          Venture Garden Group strongly believes in providing security awareness
          for its staff and customers to ensure protection of the security of
          our customer’s personal data. This helps keeps our customers aware and
          updated on security threats and how to identify and avoid them.
          </p><p>
            We are consistently creating and implementing security measures to
            ensure that the integrity and confidentiality of our customers
            information and transactions performed while using our platforms.
          
        </p>

        <div className="space-top-2">
          <h2>Overview</h2>
          <p>
            Malicious actors have intensified their efforts in getting
            unsuspecting users’ personal information, as a result, it is
            important that we keep our customers safe while they are using our
            applications, services, or portals.
          </p>
          <p>
            Venture Garden Group is committed to prioritizing the safety of our
            customers and we achieve this through sensitization on information
            security and tips on how to keep safe online and protect your data.
          </p>
        </div>

        <div className="space-top-2">
          <h2>Security Tips</h2>
          <div className="space-top-1">
            <h3>General Online Security </h3>
            <ul>
              <li>Ensure you system and web browser are kept updated. </li>

              <li>Be extra cautious of emails. </li>

              <li>Set strong passwords and change them regularly.</li>

              <li>Log-out of sessions or account when not in use</li>

              <li>Never leave your system open and unattended</li>

              <li>
                Use an anti-virus, personal firewall and VPN when possible{" "}
              </li>

              <li>
                Ensure a site visited is secured i.e., has “HTTPS” in the URL.
              </li>
            </ul>
          </div>

          <div className="space-top-1">
            <h3>Secured Password</h3>
            <p>
              It is important that passwords used for accounts are well
              constructed and maintained. Here are some tips on creating a
              strong password
            </p>
            <ul>
              <li>Use a minimum of 8 characters in length </li>

              <li>
                Use a combination of upper- and lower-case letters, special
                characters (such as !@#$&) and numbers{" "}
              </li>

              <li>Use a passphrase that can be easily remembered </li>

              <li>Use a password manager to store your password</li>

              <li>Change your password often or upon suspicious activity</li>

              <li>Do not repeat passwords used for other accounts </li>

              <li>Do not use names and common words / phrases </li>

              <li>Do not recycle old passwords </li>

              <li>
                Do not write your password down where it can easily be accessed{" "}
              </li>

              <li>
                Do not send your password as clear text via messaging platform{" "}
              </li>
            </ul>
          </div>

          <div className="space-top-1">
            <h3>Phishing </h3>
            <p>
              This is the process of attempting to acquire sensitive information
              by masquerading as a trustworthy entity through email. This could
              appear to be a legitimate email having a company’s name, logo.
              email signature, and or spoof company domain.
            </p>

            <h4>Validate emails </h4>
            <p>
              Emails may be sent from people you know and do not know. It is
              important that in either situation the identity of the sender of
              the email be validated. If it’s someone you know, you can reach
              out to the sender through a different medium (such as SMS or other
              medium apart from email) to validate that they actually sent you
              the email. When the sender is unknown, use a trusted search engine
              to research the fact about the sender.{" "}
            </p>

            <h4>Social Engineering Tactics </h4>
            <p>
              A phishing email could request for information or could have a
              link to a website that looks legitimate. In most cases however,
              the message would use any one of these tactics that play on your
              emotions:{" "}
            </p>
            <ul>
              <li>
                Authority: uses a position of seniority to exploit a user’s
                emotions
              </li>

              <li>
                Urgency: uses the need to perform an action within a specified
                period to pressure users to perform an action{" "}
              </li>

              <li>
                Scarcity: uses the promotion of a limited quantity of an
                enticing offer to trick users into performing an action{" "}
              </li>

              <li>
                Consensus: uses the enticing ideas that people within your
                network are partaking in an activity and tries to make you
                perform an action based on it.{" "}
              </li>

              <li>
                Familiarity: uses pretence of someone you know and trust to
                trick you into performing an action.
              </li>

              <li>
                Reciprocity: this is also known as quid-pro-quo and requires you
                to do something in exchange for something else.
              </li>
            </ul>

            <h4>Links and Attachments </h4>
            <p>
              Be careful the call to action in an email such as clicking of
              links and opening of attachments. You should check for the actual
              site being referenced in a link by carefully hovering your mouse
              over it and checking the following:
            </p>
            <ul>
              <li>
                that the sender and content of the email aligns with the and the
                URL path shown in the link.{" "}
              </li>

              <li>
                Check for grammatical errors in spellings in the URL path{" "}
              </li>

              <li>
                Check to see if the path is secured - “HTTPS” sites mean the
                path is encrypted, however, “HTTP” means the site is not
                protected and should be careful in accessing it.{" "}
              </li>

              <li>Check that the path’s length is short and meaningful.</li>
            </ul>
            <p>
              Be careful of attachments in an email as they can be used to
              deliver and install malware that can cause a lot of harm to your
              computer and information.
            </p>
            <p>
              {" "}
              The essence of this tricky is to have you disclose personal
              information such as user ID or passwords, credit card information,
              account number, etc. It is important that you be sceptical of
              unsolicited or unexpected emails you receive and avoid clicking on
              suspicious links, downloading attachments and responding to
              unknown senders.
            </p>
          </div>

          <div className="space-top-1">
            <h3>Pharming </h3>
            <p>
              This occurs when you type in a web address in your browser but get
              redirected to a fraudulent site that looks like the legitimate
              site without your knowledge or consent. To safeguard yourself,
              watch out for spelling errors, logos that looks out of place,
              unusual questions and when in doubt, log out of the site.{" "}
            </p>
          </div>

          <div className="space-top-1">
            <h3>Smishing and Vishing </h3>

            <p>
              Smishing is an act of using SMS test messages to lure a user into
              performing specific actions. Vishing, known as phone phishing, is
              when a malicious actor tries to gain access to user’s private
              information through a phone conversation.{" "}
            </p>

            <p>
              Watch out for unknown individuals who ask for information through
              suspicious messages or phone calls. Do not share information if
              you are unsure of the person communicating with you.{" "}
            </p>
          </div>
        </div>

        <div className="space-top-2">
          <h2>Contact</h2>
          <p>
            If you want to lodge a complaint over a security breaches, need our
            help with figuring out how to identify possible security attack or
            have further requests, please contact the Venture Garden Group’s
            Information Security Team at{" "}
            <a
              href="security@venturegardengroup.com."
              style={{ textDecoration: "underline", color: "inherit" }}
            >
              security@venturegardengroup.com.
            </a>{" "}
          </p>
        </div>
      </div>
    </main>
  );
};

export default SecurityAwarenessPage;

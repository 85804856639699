import React, { useState } from "react";
// @ts-ignore
import Fade from "react-reveal/Fade";
// @ts-ignore
import Zoom from "react-reveal/Zoom";

import UserGear from "../assets/updated-svg/UserGear.svg";
import UserPlus from "../assets/updated-svg/UserPlus.svg";
import Handshake from "../assets/updated-svg/Handshake.svg";
import Database from "../assets/updated-svg/Database.svg";
import DesktopTower from "../assets/updated-svg/DesktopTower.svg";
import CircleWavy from "../assets/updated-svg/CircleWavy.svg";
import Gear from "../assets/updated-svg/Gear.svg";
import Wallets from "../assets/updated-svg/Wallets.svg";
import BookBookmark from "../assets/updated-svg/BookBookmark.svg";
import Calendar from "../assets/updated-svg/Calendar.svg";
import Money from "../assets/updated-svg/Money.svg";
import ClipboardText from "../assets/updated-svg/ClipboardText.svg";
import GraduationCap from "../assets/updated-svg/GraduationCap.svg";
import Cpu from "../assets/updated-svg/Cpu.svg";
import Arrows from "../assets/updated-svg/Arrows.svg";
import Pencil from "../assets/updated-svg/Pencil.svg";
import Users from "../assets/updated-svg/Users.svg";
import Notebook from "../assets/updated-svg/Notebook.svg";
import Infinity from "../assets/updated-svg/Infinity.svg";
import Archive from "../assets/updated-svg/Archive.svg";
import Chalkboard from "../assets/updated-svg/Chalkboard.svg";
import Bank from "../assets/updated-svg/Bank.svg";
import FingerPrint from "../assets/updated-svg/FingerPrint.svg";

import "../assets/css/vigilearn.css";
import learning from "../assets/img/learning.svg";
import exam from "../assets/img/exam.svg";
import exam2 from "../assets/img/exam2.svg";
import education2 from "../assets/img/education2.svg";
import download1 from "../assets/img/download1.svg";
import application from "../assets/img/application 1.svg";
import information from "../assets/img/information.svg";
import apply from "../assets/img/apply.svg";
import sisImage from "../assets/img/sis-product-image.png";

export interface AppVigiLearnPageProps {}

const AppVigiLearnPage: React.FC<AppVigiLearnPageProps> = () => {
  const [selected, setSelected] = useState("learning");

  return (
    <>
      <Zoom>
        <div className="d-lg-flex position-relative vigihero">
          <div className="container d-lg-flex align-items-lg-center space-3 min-vh-lg-100">
            <Fade left>
              <div
                className="col-lg-6 card p-4"
                style={{
                  background: "rgba(24, 24, 24, 0.25)",
                  border: "none",
                  padding: "20px 12px",
                  height: "auto",
                }}
              >
                <div className="mb-5 mt-11">
                  <h5 style={{ color: "#fff" }}>VIGILEARN</h5>
                  <br />
                  <h1
                    className="display-4 mb-3"
                    style={{ color: "#ffff", fontSize: "36px" }}
                  >
                    Complete Education Management In A Box.
                  </h1>
                </div>

                <p className="lead" style={{ color: "#ffff" }}>
                  VigiLearn is an end-to-end suite of products designed to
                  provide a faster and more efficient way of managing
                  administrative and academic related processes.
                </p>
                <br />
                <div>
                  {/* <a
                    className="btn btn-primary btn-wide transition-3d-hover"
                    target="_blank"
                    rel="noreferrer"
                    href="http://vigilearn.com/"
                  >
                    Learn More
                  </a> */}
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </Zoom>
      <div className=" laptopbg  min-vh-lg-100">
        <div className="space-2">
          <h5 className="text-center">OUR SOLUTIONS</h5>
          <br />
          <div className="container ">
            <div className="row">
              <div className="col-md-3">
                <button
                  onClick={() => setSelected("learning")}
                  className="btn btn-block nbtn transition-3d-hover card-hover"
                  style={{
                    background: selected === "learning" ? "#E8EFFD" : "#fff",
                    color: selected === "learning" ? "#0F42A4" : "",
                    border: selected === "learning" ? "1px solid #0F42A4" : "",
                  }}
                >
                  <img
                    src={selected === "learning" ? learning : download1}
                    alt=""
                  />
                  <br />
                  Learning Management System
                </button>
                <br />
              </div>
              <div className="col-md-3">
                <button
                  onClick={() => setSelected("apply")}
                  className="btn btn-block nbtn transition-3d-hover card-hover"
                  style={{
                    background: selected === "apply" ? "#E8EFFD" : "#fff",
                    color: selected === "apply" ? "#0F42A4" : "",
                    border: selected === "apply" ? "1px solid #0F42A4" : "",
                  }}
                >
                  <img
                    src={selected === "apply" ? application : apply}
                    alt=""
                  />
                  <br />
                  Apply Portal
                </button>
                <br />
              </div>
              <div className="col-md-3">
                <button
                  onClick={() => setSelected("information")}
                  className="btn btn-block nbtn transition-3d-hover card-hover"
                  style={{
                    background: selected === "information" ? "#E8EFFD" : "#fff",
                    color: selected === "information" ? "#0F42A4" : "",
                    border:
                      selected === "information" ? "1px solid #0F42A4" : "",
                  }}
                >
                  <img
                    src={selected === "information" ? education2 : information}
                    alt=""
                  />
                  <br />
                  Student Information System
                </button>
                <br />
              </div>
              <div className="col-md-3">
                <button
                  onClick={() => setSelected("exam")}
                  className="btn btn-block nbtn transition-3d-hover card-hover"
                  style={{
                    background: selected === "exam" ? "#E8EFFD" : "#fff",
                    color: selected === "exam" ? "#0F42A4" : "",
                    border: selected === "exam" ? "1px solid #0F42A4" : "",
                  }}
                >
                  <img src={selected === "exam" ? exam2 : exam} alt="" />
                  <br />
                  Exam Portal
                </button>
                <br />
              </div>
            </div>
          </div>
        </div>
        <div className=" container">
          <div className="row">
            <div className="offset-sm-1 col-sm-10">
                <div className="embed-responsive embed-responsive-16by9">
                  <iframe
                    src={
                      selected === "learning"
                        ? "https://player.vimeo.com/video/549197830"
                        : selected === "apply"
                        ? "https://player.vimeo.com/video/549197147"
                        : selected === "information"
                        ? "https://player.vimeo.com/video/564136209"
                        : "https://player.vimeo.com/video/549195132"
                    }
                    frameBorder="0"
                    allow="autoplay; encrypted-media"
                    allowFullScreen
                    title="video"
                  />
                </div>
            </div>
          </div>
        </div>
      </div>
      <Fade left>
        {selected == "learning" ? (
          <div className="space-2" style={{ background: "#E9FAFB" }}>
            <div className="container ">
              <div className="row space-2">
                <div className="col-sm-6 col-lg-4 px-2 px-lg-3 mb-3 mb-lg-0 mt-3 card-hover transition-3d-hover p-4">
                  <div className="vigi-card-icon">
                    <img src={DesktopTower} alt="" />
                  </div>

                  <br />
                  <h3 className="vigi-card-header-text">Virtual Classroom</h3>
                  <div>
                    <p className="text-dark">
                      Classrooms don’t need walls and physical boundaries, they
                      can be twice as efficient with these limitations absent.
                      Now imagine a classroom without walls, but the best
                      virtual classroom experience.
                    </p>
                  </div>
                </div>

                <div className="col-sm-6 col-lg-4 px-2 px-lg-3 mb-3 mb-lg-0 mt-3 card-hover transition-3d-hover p-4">
                  <div className="vigi-card-icon">
                    <img src={UserGear} alt="" />
                  </div>
                  <br />
                  <h3 className="vigi-card-header-text">User Management</h3>
                  <div>
                    <p className="text-dark">
                      Our LMS provides simple interfaces for all user instances.
                      Whether you're an Admin, Student, or a Tutor, you'll find
                      our interfaces user-friendly and easy to navigate. For any
                      type of learning institution, managing users is as simple
                      as a click or two.
                    </p>
                  </div>
                </div>

                <div className="col-sm-6 col-lg-4 px-2 px-lg-3 mb-3 mb-lg-0 mt-3 card-hover transition-3d-hover p-4">
                  <div className="vigi-card-icon">
                    <img src={Database} alt="" />
                  </div>
                  <br />
                  <h3 className="vigi-card-header-text">Big Data Analytics</h3>
                  <div>
                    <p className="text-dark ">
                      Using Big data as a core tooling resource for revealing
                      insights from diverse, complex datasets can revolutionize
                      interactions and content delivery across all electronic
                      platforms.
                    </p>
                  </div>
                </div>

                <div className="col-sm-6 col-lg-4 px-2 px-lg-3 mb-3 mb-lg-0 mt-3 card-hover transition-3d-hover p-4">
                  <div className="vigi-card-icon">
                    <img src={Handshake} alt="" />
                  </div>
                  <br />
                  <h3 className="vigi-card-header-text">Social Networking</h3>
                  <div>
                    <p className="text-dark">
                      Forums are great in promoting self-awareness and
                      encouraging social interaction between students or with
                      tutors.
                    </p>
                  </div>
                </div>

                <div className="col-sm-6 col-lg-4 px-2 px-lg-3 mb-3 mb-lg-0 mt-3 card-hover transition-3d-hover p-4">
                  <div className="vigi-card-icon">
                    <img src={CircleWavy} alt="" />
                  </div>
                  <br />
                  <h3 className="vigi-card-header-text">Service & Support</h3>
                  <div>
                    <p className="text-dark">
                      Our robust support channels are easily accessible to
                      provide assistance and ensure the experience of every
                      user, whether you are a Learner, Tutor or Admin, is
                      completely seamless.
                    </p>
                  </div>
                </div>

                <div className="col-sm-6 col-lg-4 px-2 px-lg-3 mb-3 mb-lg-0 mt-3  card-hover transition-3d-hover p-4">
                  <div className="vigi-card-icon">
                    <img src={Gear} alt="" />
                  </div>
                  <br />
                  <h3 className="vigi-card-header-text">Customization</h3>
                  <div>
                    <p className="text-dark ">
                      Our LMS enables the creation and adjustment of a custom
                      learning environment for each learner. With features such
                      as customized polls, course allocation, and custom
                      deadlines for individual learner submissions, an
                      experience tailor-made to suit each user is guaranteed.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : selected == "apply" ? (
          <div className="space-2" style={{ background: "#E9FAFB" }}>
            <div className="container ">
              <div className="row space-2">
                <div className="col-sm-6 col-lg-3 px-2 px-lg-3 mb-3 mb-lg-0 mt-3 card-hover transition-3d-hover p-4">
                  <div className="vigi-card-icon">
                    <img src={UserPlus} alt="" />
                  </div>
                  <br />
                  <h3 className="vigi-card-header-text">User screening</h3>
                  <div>
                    <p className="text-dark">
                      Administrators have the capability to assess active user
                      profiles based on set parameters.
                    </p>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-3 px-2 px-lg-3 mb-3 mb-lg-0 mt-3 card-hover transition-3d-hover p-4">
                  <div className="vigi-card-icon">
                    <img src={Bank} alt="" />
                  </div>
                  <br />
                  <h3 className="vigi-card-header-text">Payment Integration</h3>
                  <div>
                    <p className="text-dark">
                      By integrating with reliable fintech platforms, the portal
                      facilitates the ease of billing and fund collection.
                    </p>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-3 px-2 px-lg-3 mb-3 mb-lg-0 mt-3 card-hover transition-3d-hover p-4">
                  <div className="vigi-card-icon">
                    <img src={Database} alt="" />
                  </div>
                  <br />
                  <h3 className="vigi-card-header-text">Big Data Analytics</h3>
                  <div>
                    <p className="text-dark ">
                      By using Big data as a core tooling resource for revealing
                      insights from multiple data points, the platform aids
                      assessment and planning activities of administrators.
                    </p>
                  </div>
                </div>

                <div className="col-sm-6 col-lg-3 px-2 px-lg-3 mb-3 mb-lg-0 mt-3 card-hover transition-3d-hover p-4">
                  <div className="vigi-card-icon">
                    <img src={UserPlus} alt="" />
                  </div>
                  <br />
                  <h3 className="vigi-card-header-text">User Management</h3>
                  <div>
                    <p className="text-dark">
                      VigiLearnApply provides simple interfaces for all user
                      instances. Whether you're an Admin or an Applicant, you'll
                      find our interfaces user-friendly and easy to navigate.
                      For any type of institution, managing users is as simple
                      as a click or two.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : selected == "information" ? (
          <div className="space-2" style={{ background: "#E9FAFB" }}>
            <div className="container ">
              <div className="row space-2">
                <div className="col-sm-6 col-lg-4 px-2 px-lg-3 mb-3 mb-lg-0 mt-3 card-hover transition-3d-hover p-4">
                  <div className="vigi-card-icon">
                    <img src={Cpu} alt="" />
                  </div>
                  <br />
                  <h3 className="vigi-card-header-text">Data Management</h3>
                  <div>
                    <p className="text-dark">
                      This solution provides a centralized place to institute
                      data, which also includes a data backup facility. Admins
                      can easily access student records, add new entries, print
                      the records in a single click, export data, easily
                      customize fields and update information on any records.
                    </p>
                  </div>
                </div>

                <div className="col-sm-6 col-lg-4 px-2 px-lg-3 mb-3 mb-lg-0 mt-3 card-hover transition-3d-hover p-4">
                  <div className="vigi-card-icon">
                    <img src={GraduationCap} alt="" />
                  </div>
                  <br />
                  <h3 className="vigi-card-header-text">
                    Admission/Registration Management
                  </h3>
                  <div>
                    <p className="text-dark">
                      VigiLearn SIS stores the academic records of all users and
                      allows admins to generate custom reports which can include
                      any information tied to user profiles, and even limit
                      accessibility to specific users. This solution also gives
                      an automatic allotment of batches &amp; subjects to
                      students for every academic year.
                    </p>
                  </div>
                </div>

                <div className="col-sm-6 col-lg-4 px-2 px-lg-3 mb-3 mb-lg-0 mt-3 card-hover transition-3d-hover p-4">
                  <div className="vigi-card-icon">
                    <img src={Money} alt="" />
                  </div>
                  <br />
                  <h3 className="vigi-card-header-text">Fee Payment</h3>
                  <div>
                    <p className="text-dark ">
                      With VigiLearn SIS, fees can be collected online by
                      integrating the SIS with options of Payment Gateways
                      available. Admins can also schedule fee collection dates
                      &amp; set up payments based on chosen parameters. Payment
                      receipts can also be generated for all users at the click
                      of a button.
                    </p>
                  </div>
                </div>

                <div className="col-sm-6 col-lg-4 px-2 px-lg-3 mb-3 mb-lg-0 mt-3 card-hover transition-3d-hover p-4">
                  <div className="vigi-card-icon">
                    <img src={ClipboardText} alt="" />
                  </div>
                  <br />
                  <h3 className="vigi-card-header-text">Reporting</h3>
                  <div>
                    <p className="text-dark">
                      You can check reports related to finance, users, courses
                      and much more. Reports are dynamic and get automatically
                      updated as the data changes, and can be exported in
                      various formats including XML, CSV, Word & PDF.
                    </p>
                  </div>
                </div>

                <div className="col-sm-6 col-lg-4 px-2 px-lg-3 mb-3 mb-lg-0 mt-3 card-hover transition-3d-hover p-4">
                  <div className="vigi-card-icon">
                    <img src={BookBookmark} alt="" />
                  </div>

                  <br />
                  <h3 className="vigi-card-header-text">Gradebook Management</h3>
                  <div>
                    <p className="text-dark">
                      VigiLearn SIS allows admins to import marks in bulk, and
                      also easily edit the mark of the students for active and
                      inactive batches. The Gradebook provides all student
                      grades in one place, and users can view and download GPA
                      and CGPA reports.
                    </p>
                  </div>
                </div>

                <div className=" col-sm-6 col-lg-4 px-2 px-lg-3 mb-3 mb-lg-0 mt-3  card-hover transition-3d-hover p-4">
                  <div className="vigi-card-icon">
                    <img src={Calendar} alt="" />
                  </div>
                  <br />
                  <h3 className="vigi-card-header-text">
                    Timetable/Calendar Management
                  </h3>
                  <div>
                    <p className="text-dark ">
                      With VigiLearn SIS, admin can set up timetable for courses
                      and also schedule dates for tests, assignment deadlines,
                      and exams. This timetable can be view by the students on
                      their SIS platform.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : selected == "exam" ? (
          <div className="space-2" style={{ background: "#E9FAFB" }}>
            <div className="container ">
              <div className="row space-2">
                <div className="col-sm-6 col-lg-4 px-2 px-lg-3 mb-3 mb-lg-0 mt-3 card-hover transition-3d-hover p-4">
                  <div className="vigi-card-icon">
                    <img src={Bank} alt="" />
                  </div>
                  <br />
                  <h3 className="vigi-card-header-text">Question Bank Management</h3>
                  <div>
                    <p className="text-dark">
                      The exam software allows its users to manage and create
                      different question paper templates and set-up question
                      banks that are reusable anytime. The inclusion of text
                      editors and images could make the question paper
                      presentation more appealing.
                    </p>
                  </div>
                </div>

                <div className="col-sm-6 col-lg-4 px-2 px-lg-3 mb-3 mb-lg-0 mt-3 card-hover transition-3d-hover p-4">
                  <div className="vigi-card-icon">
                    <img src={FingerPrint} alt="" />
                  </div>
                  <br />
                  <h3 className="vigi-card-header-text">Single Sign-On</h3>
                  <div>
                    <p className="text-dark">
                      The examination portal can be integrated to any LMS and it
                      allows students access the portal with the same
                      credential.
                    </p>
                  </div>
                </div>

                <div className="col-sm-6 col-lg-4 px-2 px-lg-3 mb-3 mb-lg-0 mt-3 card-hover transition-3d-hover p-4">
                  <div className="vigi-card-icon">
                    <img src={ClipboardText} alt="" />
                  </div>
                  <br />
                  <h3 className="vigi-card-header-text">Automatic Reporting </h3>
                  <div>
                    <p className="text-dark ">
                      At the end of every exam session, the result is displayed
                      for the student to see and also updated on the
                      administrative end.
                    </p>
                  </div>
                </div>

                <div className="col-sm-6 col-lg-4 px-2 px-lg-3 mb-3 mb-lg-0 mt-3 card-hover transition-3d-hover p-4">
                  <div className="vigi-card-icon">
                    <img src={Chalkboard} alt="" />
                  </div>
                  <br />
                  <h3 className="vigi-card-header-text">Exam Instructions </h3>
                  <div>
                    <p className="text-dark">
                      An administrator can set exam instructions prior to the
                      exam date, instruction can also be edited. The instruction
                      will be displayed before the examination starts.
                    </p>
                  </div>
                </div>

                <div className="col-sm-6 col-lg-4 px-2 px-lg-3 mb-3 mb-lg-0 mt-3 card-hover transition-3d-hover p-4">
                  <div className="vigi-card-icon">
                    <img src={Arrows} alt="" />
                  </div>
                  <br />
                  <h3 className="vigi-card-header-text">Import and Export facilities</h3>
                  <div>
                    <p className="text-dark">
                      You can import & export users, exams, responses, reports
                      with ease.
                    </p>
                  </div>
                </div>

                <div className="col-sm-6 col-lg-4 px-2 px-lg-3 mb-3 mb-lg-0 mt-3 card-hover transition-3d-hover p-4">
                  <div className="vigi-card-icon">
                    <img src={Infinity} alt="" />
                  </div>
                  <br />
                  <h3 className="vigi-card-header-text">Random Ordering of Questions</h3>
                  <div>
                    <p className="text-dark">
                      You can automatically randomize the order of the pages,
                      questions and choices for each respondent that accesses
                      the test.
                    </p>
                  </div>
                </div>

                <div className="col-sm-6 col-lg-4 px-2 px-lg-3 mb-3 mb-lg-0 mt-3  card-hover transition-3d-hover p-4">
                  <div className="vigi-card-icon">
                    <img src={Pencil} alt="" />
                  </div>
                  <br />
                  <h3 className="vigi-card-header-text">White Label and Branding </h3>
                  <div>
                    <p className="text-dark ">
                      The portal allows white label, customize to suite your
                      organization and business model.
                    </p>
                  </div>
                </div>

                <div className="col-sm-6 col-lg-4 px-2 px-lg-3 mb-3 mb-lg-0 mt-3  card-hover transition-3d-hover p-4">
                  <div className="vigi-card-icon">
                    <img src={Archive} alt="" />
                  </div>
                  <br />
                  <h3 className="vigi-card-header-text">
                    Organization and User Management{" "}
                  </h3>
                  <div>
                    <p className="text-dark ">
                      You can create & assign users with login access. You can
                      group users within the same organization or class for ease
                      of examining.
                    </p>
                  </div>
                </div>

                <div className="col-sm-6 col-lg-4 px-2 px-lg-3 mb-3 mb-lg-0 mt-3  card-hover transition-3d-hover p-4">
                  <div className="vigi-card-icon">
                    <img src={Users} alt="" />
                  </div>
                  <br />
                  <h3 className="vigi-card-header-text">
                    Easy and Multiple Scoring Methods
                  </h3>
                  <div>
                    <p className="text-dark ">
                      Results can either be graded in percentage or just numbers
                      depending on how the administrator sets it.
                    </p>
                  </div>
                </div>

                <div className="col-sm-6 col-lg-4 px-2 px-lg-3 mb-3 mb-lg-0 mt-3  card-hover transition-3d-hover p-4">
                  <div className="vigi-card-icon">
                    <img src={BookBookmark} alt="" />
                  </div>
                  <br />
                  <h3 className="vigi-card-header-text">
                    Detailed Report and Dashboard
                  </h3>
                  <div>
                    <p className="text-dark ">
                      An online exam software should give you enough insights
                      you would need through its advanced analytical reporting
                      and personalized dashboards. It should have the ability to
                      produce different kinds of real-time reports for the
                      institutional bigger picture. Reports that gauge the
                      success of a program along with students’ progress, which
                      could help in addressing the gaps at the early stage.
                    </p>
                  </div>
                </div>

                <div className="col-sm-6 col-lg-4 px-2 px-lg-3 mb-3 mb-lg-0 mt-3  card-hover transition-3d-hover p-4">
                  <div className="vigi-card-icon">
                    <img src={Notebook} alt="" />
                  </div>
                  <br />
                  <h3 className="vigi-card-header-text">
                    Configuration of question type with different level of
                    difficulty 
                  </h3>
                  <div>
                    <p className="text-dark ">
                      This option allows easy creation of several question types
                      including multiple-choice, quizzes, and mathematical
                      formulas. It also allows the upload of images alongside
                      with questions.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </Fade>
    </>
  );
};

export default AppVigiLearnPage;

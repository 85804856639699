import React from "react";
import "../assets/css/policy.css";

export interface CookiePolicyPageProps {}

const CookiePolicyPage: React.SFC<CookiePolicyPageProps> = () => {
  return (
    <main>
      <div className="container privacy-wrapper space-top-3 space-bottom-2">
        <h1 className="text-center space-bottom-1">Cookie Policy</h1>
        <p>
          Please read this cookie policy (“cookie policy”, "policy") carefully
          before using this website (“website”, "service") operated by [Edutech]
          ("us", 'we", "our").{" "}
        </p>

        <div className="space-top-1">
          <h4>What are Cookies?</h4>
          <p>
            Cookies are simple text files that are stored on your computer or
            mobile device by a website’s server. Each cookie is unique to your
            web browser. It will contain some anonymous information such as a
            unique identifier, website’s domain name, and some digits and
            numbers.{" "}
          </p>

          <div className="space-top-1">
            <h4>What type of cookies do we use?</h4>
            <span>
              <i>Necessary Cookies</i>
            </span>{" "}
            <br />
            <span>
              Necessary cookies allow us to offer you the best possible
              experience when accessing and navigating through our website and
              using its features. For example, these cookies let us recognize
              that you have created an account and have logged into that
              account.{" "}
            </span>
          </div>

          <div className="space-top-1">
            <span>
              <i>Functionality Cookies</i>
            </span>
            <br />
            <span>
              Functionality cookies let us operate the site in accordance with
              the choices you make. For example, we will recognize your username
              and remember how you customized the site during future visits.{" "}
            </span>
          </div>

          <div className="space-top-1">
            <span>
              <i>Performance Cookies</i>
            </span>
            <br />
            <span>
              These are used to gather data on how users use our website and the
              pages visited most often. They monitor only the performance of our
              website as the user interacts with it. All data collected by these
              cookies are anonymous and are solely used to improve the
              functionality of our website.
            </span>
          </div>

          {/* <div className="space-top-1">
            <span>
              <i>Advertisment/Targeting Cookies</i>
            </span>
            <br />
            <span>
              These cookies are placed there by us to monitor your preference so
              as to display advertisements that are relevant to you. You have
              the option to decline the activation of these cookies.
            </span>
          </div> */}
        </div>

          <div className="space-top-2">
          <h4>How to delete Cookies ?</h4>
        <p>
          If you want to restrict or block the cookies that are set by our
          website, you can do so through your browser setting. 
        </p>

        <h4>Contacting us</h4>
        <p>
          If you have any questions about this policy or our use of cookies,
          please contact us.{" "}
        </p>
          </div>
        
      </div>
    </main>
  );
};

export default CookiePolicyPage;

import React from 'react';
import { useLocation } from 'react-router-dom';
import { NavLink, Link } from 'react-router-dom';
import '../assets/css/header.css';
import gardenlogo from '../assets/updated-svg/logo.svg';
import { RiArrowDropDownLine } from 'react-icons/ri';

export interface AppHeaderProps {}

const AppHeader: React.SFC<AppHeaderProps> = () => {
	const location = useLocation();

	let background =
		location.pathname == '/partners' ||
		location.pathname == '/blog' ||
		location.pathname == '/blog-details' ||
		location.pathname == '/faq' ||
		location.pathname == '/contact' ||
		location.pathname == '/vigi-learn'
			? '#FFFFFF'
			: '#FFFFFF';

	return (
		<React.Fragment>
			<header
				id='header'
				className='header header-color header-box-shadow-on-scroll header-abs-top header-bg-transparent header-show-hide'
				style={{ position: 'fixed', background }}>
				<div className='header-section header-color'>
					<div id='logoAndNav' className='container'>
						<nav className='js-mega-menu navbar navbar-expand-lg'>
							<Link className='navbar-brand' to='/' aria-label='Front'>
								<img src={gardenlogo} style={{ width: '10rem !important' }} className='img-fluid' alt='Logo' />
							</Link>

							<button
								type='button'
								style={{ background }}
								className='navbar-toggler btn btn-icon btn-sm rounded-circle'
								aria-label='Toggle navigation'
								aria-expanded='false'
								aria-controls='navBar'
								data-toggle='collapse'
								data-target='#navBar'>
								<span className='navbar-toggler-default'>
									<svg width='18' height='16' viewBox='0 0 18 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
										<path d='M0 0H18V2H0V0ZM0 7H18V9H0V7ZM0 14H18V16H0V14Z' fill='#041644' />
									</svg>
								</span>
								<span className='navbar-toggler-toggled'>
									<svg width='14' height='14' viewBox='0 0 18 18' xmlns='http://www.w3.org/2000/svg'>
										<path
											fill='currentColor'
											d='M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z'
										/>
									</svg>
								</span>
							</button>

							<div id='navBar' className='collapse navbar-collapse'>
								<div className='navbar-body header-abs-top-inner'>
									<ul className='navbar-nav'>
										<li className='hs-has-mega-menu navbar-nav-item'>
											<NavLink id='homeMegaMenu' className='hs-mega-menu-invoker nav-link' to='/about' activeClassName='active'>
												About Us
											</NavLink>
										</li>

										{/* <li className='nav-item dropdown' style={{ cursor: 'pointer' }}>
											<div
												className='nav-item nav-link  p-0 m-0  d-flex align-items-center'
												id='bd-versions'
												data-toggle='dropdown'
												aria-haspopup='true'
												aria-expanded='false'>
												<a className='hs-mega-menu-invoker nav-link'>
													Products
													<RiArrowDropDownLine size='27px' color='#0D0F11' className='drop-down' />
												</a>
											</div>

											<div className='dropdown-menu dropdown-menu-right' style={{ borderTop: 'none' }} aria-labelledby='bd-versions'>
												<NavLink className={location.pathname === '/vigi-learn' ? 'dropdown-item active' : 'dropdown-item'} to='/vigi-learn'>
													VigiLearn
												</NavLink>
												<NavLink className={location.pathname === '/edu-collect' ? 'dropdown-item active' : 'dropdown-item'} to='/edu-collect'>
													Bursery
												</NavLink>
											</div>
										</li> */}

										<li className='hs-has-mega-menu navbar-nav-item'>
											<NavLink id='homeMegaMenu' className='hs-mega-menu-invoker nav-link' to='/blog'>
												Blog
											</NavLink>
										</li>

										<li className="hs-has-mega-menu navbar-nav-item">
                      <Link
                        id="homeMegaMenu"
                        className="hs-mega-menu-invoker nav-link"
                        to="/partners"
                      >
                        Partners
                      </Link>
                    </li>
										{/* <li className='hs-has-mega-menu navbar-nav-item'>
											<a id='homeMegaMenu' target="_blank" className='hs-mega-menu-invoker nav-link' href='https://marketplace.edutech.global/institutions'>
												Partners
											</a>
										</li> */}

										<li className='hs-has-mega-menu navbar-nav-item'>
											<Link id='homeMegaMenu' className='hs-mega-menu-invoker nav-link' to='/referral'>
												Refer Us
											</Link>
										</li>

										<li className='hs-has-mega-menu navbar-nav-item'>
											<NavLink id='homeMegaMenu' className='hs-mega-menu-invoker nav-link' to='/contact'>
												Contact Us
											</NavLink>
										</li>
									</ul>
								</div>
							</div>
						</nav>
					</div>
				</div>
			</header>
		</React.Fragment>
	);
};

export default AppHeader;

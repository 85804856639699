import React, { useState } from "react";
import * as Yup from "yup";
import axiosRequest from "../helpers/AxiosRequest";
import { Modal } from "react-bootstrap";
import { Formik, Form } from "formik";
import { useToasts } from "react-toast-notifications";

import "../assets/css/partnerform.css";

const PartnerForm = ({ openModal, handleShow }) => {
  const { addToast } = useToasts();
  const [loading, setLoading] = useState(false);

  const initialValues = {
    name: "",
    companyName: "",
    email: "",
    phoneNo: "",
    message: "",
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .test({
        name: "validator-custom-name",
        test: function (value) {
          return /\d/.test(value)
            ? this.createError({
                message: "Invalid name",
                path: "name",
              })
            : true;
        },
      })
      .min(3, "Minimum of 3 characters")
      .required("Name is empty"),
    companyName: Yup.string()
      .test({
        name: "validator-custom-name",
        test: function (value) {
          return /\d/.test(value)
            ? this.createError({
                message: "Invalid name",
                path: "companyName",
              })
            : true;
        },
      })
      .min(3, "Minimum of 3 characters")
      .required("Company Name is empty"),
    email: Yup.string()
      .email("Invalid email format!")
      .required("Email is empty"),

    phoneNo: Yup.string()

      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        "Enter a valid phone number"
      )
      .min(9, "Minimum of 9 characters")
      .required("Phone Number is empty"),
    message: Yup.string()
      .min(50, "Minimum of 50 characters")
      .max(300, "Not more than 300 characters")
      .required("Message is empty"),
  });

  const onSubmit = async (
    { name, companyName, phoneNo, email, message },
    resetForm
  ) => {
    setLoading(true);
    try {
      const payload = {
        recipientEmail: "support@edutechng.com",
        name: "Edutech Support",
        message: `Name :   ${name}
                    <br/><br/>
                    Company:   ${companyName}
                    <br/><br/>
                    Phone Number:  ${phoneNo}
                    <br/><br/>
                    Email:   ${email}
                    <br/><br/>
                    Message: ${message}`,
      };
      const res = await axiosRequest("post", "MailingList/sendmail", payload);
      if (res.data.requestSuccessful === true) {
        resetForm();
        handleShow();
        addToast("Request submitted successfully", {
          appearance: "success",
          autoDismiss: true,
          placement: "top-center",
        });
      } else {
        addToast("An error occured", {
          appearance: "error",
          autoDismiss: true,
        });
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Modal show={openModal} onHide={handleShow} className="modal-open">
        <div
          className="modal-content"
          style={{ borderRadius: ".25rem" }}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="partnerModalLabel">
              Patner with us
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" onClick={handleShow}>
                &times;
              </span>
            </button>
          </div>
          <div className="modal-body" style={{ padding: "1.5rem 0.3rem" }}>
            <div style={{ padding: "0 1.2rem" }}>
              <div
                style={{
                  borderBottom: "1px solid #D7DCE0",
                  marginTop: "-1rem",
                }}
              ></div>
            </div>

            <div className="modal-body">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, { resetForm }) =>
                  onSubmit(values, resetForm)
                }
              >
                {({ errors, touched, getFieldProps, values }) => (
                  <Form noValidate autoComplete="off">
                    <div className="row">
                      <div className="col-md-6 form-group">
                        <label htmlFor="name" className="input-label">
                          Full Name <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          {...getFieldProps("name")}
                          id="name"
                          placeholder="eg. Jane Doe"
                        />
                        <small className="text-danger">
                          {errors.name && touched.name && errors.name}
                        </small>
                      </div>
                      <div className="col-md-6 form-group">
                        <label htmlFor="name" className="input-label">
                          Company Name <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="companyName"
                          {...getFieldProps("companyName")}
                          id="name"
                          placeholder="eg. Advancely"
                        />
                        <small className="text-danger">
                          {errors.companyName &&
                            touched.companyName &&
                            errors.companyName}
                        </small>
                      </div>
                      <div className="col-md-6 form-group">
                        <label htmlFor="name" className="input-label">
                          Phone Number <span className="text-danger">*</span>
                        </label>
                        <input
                          type="tel"
                          className="form-control"
                          name="name"
                          {...getFieldProps("phoneNo")}
                          id="phoneNo"
                          placeholder="eg. 08038574285"
                        />
                        <small className="text-danger">
                          {errors.phoneNo && touched.phoneNo && errors.phoneNo}
                        </small>
                      </div>
                      <div className="col-md-6 form-group">
                        <label htmlFor="email" className="input-label">
                          Email Address <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          size="sm"
                          className="form-control"
                          name="email"
                          {...getFieldProps("email")}
                          id="email"
                          placeholder="eg. janedoe@example.com"
                        />
                        <small className="text-danger">
                          {errors.email && touched.email && errors.email}
                        </small>
                      </div>

                      <div className="col-md-12 form-group">
                        <label htmlFor="message" className="input-label">
                          Request Message <span className="text-danger">*</span>
                        </label>
                        <textarea
                          type="text"
                          rows={3}
                          className="form-control"
                          name="messagee"
                          {...getFieldProps("message")}
                          id="message"
                          placeholder="Enter message here"
                        />
                        <small className="text-danger d-block">
                          {errors.message && touched.message && errors.message}
                        </small>
                      </div>
                    </div>

                    <div
                      className="d-flex align-items-center mt-4"
                      style={{ gap: "1rem", justifyContent: "flex-end" }}
                    >
                      <span
                        type="button"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={handleShow}
                        style={{ color: "#0F42A4", cursor: "pointer" }}
                      >
                        <span aria-hidden="true">Cancel</span>
                      </span>
                      <button
                        type="submit"
                        disabled={loading}
                        style={{
                          padding: ".4rem 1rem",
                          cursor: loading ? "not-allowed" : "pointer",
                        }}
                        className="btn btn-primary transition-3d-hover"
                      >
                        Submit
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default PartnerForm;

// @ts-ignore
import Fade from 'react-reveal/Fade';
// @ts-ignore
import Zoom from 'react-reveal/Zoom';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react';

import abu from '../assets/img/partners_logo/Logo_ABU.png';
import acu from '../assets/img/partners_logo/Logo_ACU.png';
import alexander from '../assets/img/partners_logo/Logo_Alexander.png';
import altschool from '../assets/img/partners_logo/Logo_Alt_School.png';
import babcock from '../assets/img/partners_logo/Logo_Babcock.png';
import brookeHouse from '../assets/img/partners_logo/Logo_BrookHouse.png';
import centennial from '../assets/img/partners_logo/Logo_Centennial.png';
import cibn from '../assets/img/partners_logo/Logo_CIBN.png';
import desertSide from '../assets/img/partners_logo/Logo_DesertSide_1.jpg';
import edutech from '../assets/img/partners_logo/Logo_Edutech_1.jpg';
import empower from '../assets/img/partners_logo/Logo_Empower.png';
import esDubai from '../assets/img/partners_logo/Logo_ES_Dubai.png';
import eubusinessschool from '../assets/img/partners_logo/Logo_EU_BusinessSchool.png';
import fanshawe from '../assets/img/partners_logo/Logo_FANSHAWE.png';
import gbsDubai from '../assets/img/partners_logo/Logo_GBS_Dubai.png';
import gbsMalta from '../assets/img/partners_logo/Logo_GBS_Malta.png';
import gcMalta from '../assets/img/partners_logo/Logo_Gcmalta.png';
import gnu from '../assets/img/partners_logo/Logo_GNU.png';
import greatLakes from '../assets/img/partners_logo/Logo_GreatLakes.png';
import greenHouse from '../assets/img/partners_logo/Logo_GreenHouse_1.jpg';
import hult from '../assets/img/partners_logo/Logo_HULT.png';
import iXperience from '../assets/img/partners_logo/Logo_iXperience.png';
import iu from '../assets/img/partners_logo/Logo_IU.png';
import lsbf from '../assets/img/partners_logo/Logo_LSBF.png';
import mahe from '../assets/img/partners_logo/Logo_MAHE.png';
import mcphs from '../assets/img/partners_logo/Logo_MCPHS.png';
import miu from '../assets/img/partners_logo/Logo_MIU.png';
import naba from '../assets/img/partners_logo/Logo_NABA.png';
import norquest from '../assets/img/partners_logo/Logo_Norquest.png';
import nyit from '../assets/img/partners_logo/Logo_NYIT.png';
import obs from '../assets/img/partners_logo/Logo_OBS.png';
import r3 from '../assets/img/partners_logo/Logo_r3.png';
import ritDubai from '../assets/img/partners_logo/Logo_RIT_Dubai.png';
import ritNewyork from '../assets/img/partners_logo/Logo_RIT_NewYork.png';
import sait from '../assets/img/partners_logo/Logo_SAIT.png';
import sgu from '../assets/img/partners_logo/Logo_SGU.png';
import siu from '../assets/img/partners_logo/Logo_SIU.png';
import stephens from '../assets/img/partners_logo/Logo_Stephens.png';
import tiec from '../assets/img/partners_logo/Logo_TIEC.png';
import ucanWest from '../assets/img/partners_logo/Logo_UCW.png';
import ue from '../assets/img/partners_logo/Logo_UE.png';
import uehs from '../assets/img/partners_logo/Logo_UEHS.png';
import ukcbc from '../assets/img/partners_logo/Logo_UKCBC.png';
import unideb from '../assets/img/partners_logo/Logo_UniDeb.png';
import uniWarsaw from '../assets/img/partners_logo/Logo_UniWarsaw.png';
import uWindsor from '../assets/img/partners_logo/Logo_UWindsor.png';
import virindu from '../assets/img/partners_logo/Logo_Virindu.png';


import PartnerForm from '../components/PartnerForm';

export interface AppPartnersProps { }

const AppPartners: React.FC<AppPartnersProps> = () => {
	const [openModal, setOpenModal] = useState(false);
	const search = useLocation().search
	const searchParams = new URLSearchParams(search)

	const [partners, setPartners] = useState([
		{
			image: greatLakes,
			title: 'Great Lakes College of Toronto',
			url: 'https://greatlakes.vigilearnapply.com/login',
			category: ['All Schools', 'North America', 'Preparatory']
		},
		{
			image: ritDubai,
			title: 'Rochester Institute of Technology, Dubai (RIT)',
			url: 'https://rit.vigilearnapply.com/login',
			category: ['All Schools', 'STEM', 'Business']
		},
		{
			image: ritNewyork,
			title: 'Rochester Institute of Technology, New York (RIT)',
			url: 'https://ritnewyork.vigilearnapply.com/login',
			category: ['All Schools', 'STEM', 'Business', 'North America']
		},
		{
			image: iXperience,
			title: 'IXperience',
			url: 'https://ixperience.vigilearnapply.com/login',
			category: ['All Schools', 'STEM']
		},
		{
			image: babcock,
			title: 'Babcock University, Nigeria',
			url: 'https://babcock.vigilearnapply.com/login',
			category: ['All Schools', 'Business']
		},
		{
			image: eubusinessschool,
			title: 'EU Business School, Spain, Barcelona, Geneva, Munich',
			url: 'https://eubusinessschool.vigilearnapply.com/login',
			category: ['All Schools', 'STEM', 'Business', 'Europe']
		},
		{
			image: iu,
			title: 'IU University of Applied Sciences, Berlin, Germany',
			url: 'https://iu.vigilearnapply.com/login',
			category: ['All Schools', 'STEM', 'Business', 'Europe']
		},
		{
			image: gbsDubai,
			title: 'Global Banking School Dubai (GBS)',
			url: 'https://gbs-dubai.vigilearnapply.com/login',
			category: ['All Schools', 'STEM', 'Business']
		},
		{
			image: gbsMalta,
			title: 'Global Banking School Malta (GBS)',
			url: 'https://gbs-malta.vigilearnapply.com/login',
			category: ['All Schools', 'Business', 'Europe']
		},
		{
			image: miu,
			title: 'Manipal International University, Malaysia',
			url: 'https://miu.vigilearnapply.com/login',
			category: ['All Schools', 'STEM', 'Business']
		},
		{
			image: hult,
			title: 'Hult International Business School',
			url: 'https://hult.vigilearnapply.com/login',
			category: ['All Schools', 'Business', 'North America', 'Europe']
		},
		{
			image: gnu,
			title: 'Manipal GlobalNXT University',
			url: 'https://gnu.vigilearnapply.com/login',
			category: ['All Schools', 'STEM', 'Business']
		},
		{
			image: obs,
			title: 'Online Business School',
			url: 'https://onlinebusinessschool.vigilearnapply.com/login',
			category: ['All Schools', 'Medical', 'Europe', 'Preparatory']
		},
		// {
		// 	image: r3,
		// 	title: 'R3 Education Inc.',
		// 	url: 'https://r3.vigilearnapply.com/login',
		// 	category: ['All Schools']
		// },
		{
			image: nyit,
			title: 'New York Institute of Technology',
			url: 'https://nyit.vigilearnapply.com/login',
			category: ['All Schools', 'STEM', 'Business', 'Medical', 'North America']
		},
		{
			image: esDubai,
			title: 'Es Dubai',
			url: 'https://esenglish.vigilearnapply.com/login',
			category: ['All Schools']
		},
		{
			image: naba,
			title: 'Nuovo Accademia Di Belle Arti, Italy',
			url: 'https://nuova.vigilearnapply.com/login',
			category: ['All Schools', 'STEM', 'Europe']
		},
		// {
		// 	image: centennial,
		// 	title: 'Centennial College, Toronto',
		// 	url: 'https://centennialcollege.vigilearnapply.com/login',
		// 	category: ['All Schools']
		// },
		{
			image: brookeHouse,
			title: 'Brooke House College, London',
			url: 'https://brookehousecollege.vigilearnapply.com/login',
			category: ['All Schools', 'Europe', 'Preparatory']
		},
		{
			image: alexander,
			title: 'Alexander College',
			url: 'https://alexandercollege.vigilearnapply.com/login',
			category: ['All Schools', 'STEM', 'Business', 'North America', 'Preparatory']
		},
		{
			image: mcphs,
			title: 'Massachusetts College of Pharmacy and Health Science',
			url: 'https://mcphs-usa.vigilearnapply.com/login',
			category: ['All Schools', 'Medical', 'North America']
		},
		// {
		// 	image: lsbf,
		// 	title: 'London School of Business and Finance',
		// 	url: 'https://lbs.vigilearnapply.com/login',
		// 	category: ['All Schools', 'Business', 'Europe']
		// },
		{
			image: uWindsor,
			title: 'University of Windsor',
			url: 'https://uwindsorcanada.vigilearnapply.com/login',
			category: ['All Schools', 'STEM', 'Business', 'North America', 'Preparatory']
		},
		{
			image: ukcbc,
			title: 'UK College of Business and Computing',
			url: 'https://ukcbc.vigilearnapply.com/login',
			category: ['All Schools', 'STEM', 'Business']
		},
		// {
		// 	image: acu,
		// 	title: 'Abilene Christian University',
		// 	url: 'https://acu.vigilearnapply.com/login',
		// 	category: ['All Schools', 'removed']
		// },
		{
			image: sgu,
			title: "St. George's University, Grenada",
			url: 'https://sgu.vigilearnapply.com/login',
			category: ['All Schools', 'STEM', 'Business', 'Medical', 'North America']
		},
		{
			image: mahe,
			title: 'Manipal Academy of Higher Education (MAHE)',
			url: 'https://mahe.vigilearnapply.com/login',
			category: ['All Schools', 'STEM', 'Business']
		},
		{
			image: abu,
			title: 'Ahmadu Bello University',
			url: 'https://apply.abudlc.edu.ng/',
			category: ['All Schools', 'STEM', 'Business', 'Medical', 'Preparatory']
		},
		// {
		// 	image: sait,
		// 	title: 'Southern Alberta Institute of Technology',
		// 	url: 'https://sait.vigilearnapply.com/login',
		// 	category: ['All Schools']
		// },
		// {
		// 	image: fanshawe,
		// 	title: 'Fanshawe College',
		// 	url: 'https://fanshawe.vigilearnapply.com/login',
		// category : [removed]
		// },
		// {
		// 	image: desertSide,
		// 	title: 'Desert Side Training Institute',
		// 	url: 'https://dstidubai.vigilearnapply.com/login',
		// 	category: ['All Schools', 'removed']
		// },
		{
			image: ucanWest,
			title: 'University Canada West',
			url: 'https://ucanwest.vigilearnapply.com/login',
			category: ['All Schools', 'Business', 'North America', 'Preparatory']
		},
		// {
		// 	image: tiec,
		// 	title: 'Texas International Education Consortium',
		// 	url: 'https://tiec.vigilearnapply.com/login',
		// 	category: ['All Schools', 'removed']
		// },
		// {
		// 	image: siu,
		// 	title: 'Southern Illinois University',
		// 	url: 'https://siu.vigilearnapply.com/login',
		// 	category: ['All Schools']
		// },
		{
			image: norquest,
			title: 'Norquest College',
			url: 'https://norquest.vigilearnapply.com/login',
			category: ['All Schools', 'Medical', 'North America', 'Preparatory']
		},
		// {
		// 	image: cibn,
		// 	title: 'The Chartered Institute of Bankers, Nigeria',
		// 	url: 'https://cibng.vigilearnapply.com/login',
		// 	category: ['All Schools', 'removed']
		// },
		{
			image: altschool,
			title: 'Altschool Africa',
			url: 'https://altschoolafrica.vigilearnapply.com/login',
			category: ['All Schools', 'STEM', 'Preparatory']
		},
		{
			image: uehs,
			title: 'University of Economic & Human Sciences, Warsaw',
			url: 'https://uehsw.vigilearnapply.com/login',
			category: ['All Schools']
		},
		// {
		// 	image: ue,
		// 	title: 'Universidad Europea, Spain',
		// 	url: 'https://universidadeuropea.vigilearnapply.com/login',
		// 	category: ['All Schools']
		// },
		{
			image: virindu,
			title: 'Virindu Consulting',
			url: 'https://virinduconsulting.vigilearnapply.com/login',
			category: ['All Schools', 'Preparatory']
		},
		{
			image: uniWarsaw,
			title: 'University of Warsaw',
			url: 'https://uehsw.vigilearnapply.com/login',
			category: ['All Schools']
		},
		{
			image: stephens,
			title: 'Stephen Business School, Mauritius',
			url: 'https://stephenbs.vigilearnapply.com/login',
			category: ['All Schools', 'Preparatory']
		},
		{
			image: empower,
			title: 'Empower School of Health',
			url: 'https://empowerschoolofhealth.vigilearnapply.com/login',
			category: ['All Schools', 'Medical', 'Europe', 'Preparatory']
		},
		{
			image: gcMalta,
			title: 'Global College, Malta',
			url: 'https://gcmalta.vigilearnapply.com/login',
			category: ['All Schools', 'Business', 'Europe', 'Preparatory']
		},
		// {
		// 	image: unideb,
		// 	title: 'University of Debrecen',
		// 	url: 'https://unideb.vigilearnapply.com/login',
		// category : [removed]
		// },
	]);

	const searchParameter = searchParams.get('tag')

	useEffect(() => {
		if (searchParameter) {
			const taggedPartners = partners.filter(e => e.category.find(i => i == searchParameter))
			setPartners(taggedPartners)
		}
	}, [searchParameter])

	const [index, setIndex] = useState(0);

	const slideRight = () => {
		const nextIndex = index + 1;
		if (nextIndex > partners.length - 1) {
			setIndex(0);
		} else {
			setIndex(nextIndex);
		}
	};

	const slideLeft = () => {
		const nextIndex = index - 1;
		if (nextIndex < 0) {
			setIndex(partners.length - 1);
		} else {
			setIndex(nextIndex);
		}
	};

	const handleShow = (): void => {
		setOpenModal(!openModal);
	};

	const PartnerCard = ({ prop: { image, title, url } }: any) => (
		<div className='card_center col-sm-6 col-md-4 col-lg-3'>
			<div
				style={{
					color: "white",
					borderRadius: "10px",
					border: "1px solid #e5e5e5",
					marginBottom: "20px",
					boxShadow: "0.5em 0.5em 0.5em -0.4em rgba(0, 0, 0, 0.16)",
				}}
			>
				<div style={{ width: "100%", height: "100px", display: "flex", alignItems: "center", justifyContent: "center" }}>
					<img
						alt='school-logo' src={image}
						style={{ height: "auto", maxHeight: "100px", maxWidth: "90%" }}
					/>
				</div>
				<a target='_blank' style={{ width: "100%" }} href={url}>
					<div className='partner_card_body'>
						<span>{title}</span>
					</div>
				</a>
			</div>
		</div>
	)

	return (
		<>
			<Zoom>
				<div className='d-lg-flex position-relative partnerhero'>
					<div className='container d-lg-flex align-items-lg-center space-3 min-vh-lg-100'>
						<Fade left>
							<div
								className='col-lg-6 card p-4'
								style={{
									background: 'transparent',
									border: 'none',
									padding: '20px 12px',
									height: 'auto',
								}}>
								<div className='mb-5 mt-11'>
									<h1 className='display-4 mb-3' style={{ color: '#ffff', fontSize: '36px' }}>
										Institutions and Partners
									</h1>
								</div>

								<p className='lead' style={{ color: '#ffff' }}>
									We believe that everyone has the desire to learn and we provide all you need
									to follow your passions.
								</p>
							</div>
						</Fade>
					</div>
				</div>
			</Zoom>

			<div className='container' style={{
				padding: "0px"
			}}>
				<div className='space-1'>
					<h5 style={{ fontSize: "26px" }} className='partner__our__partner__text'>Institutions</h5>

					<div className="card_group_container col-md-12" style={{ justifyContent: "flex-start" }}>
						{
							partners.map((cardData, index) => <PartnerCard prop={cardData} key={index} />)
						}
					</div>
				</div>
			</div>

			<div
				className='container space-1'
				style={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
					background: '#03073F',
					borderRadius: '8px',
				}}>
				<h3 className='text-center text-light'>
					"Alone we can do so little; together we can do so much"
				</h3>
				<h2 className='text-center text-light'>
					Ready To Partner With Us
					<br />
					To Get More People Access To
					<br />
					Quality Education?
				</h2>
				<br />
				<button
					className='btn btn-lg btn-outline-primary transition-3d-hover '
					style={{
						padding: '10px 25px',
						borderRadius: '4px',
						background: '#fff',
						color: '#0F42A4',
					}}
					onClick={handleShow}>
					Become a Partner
				</button>
			</div>

			<PartnerForm openModal={openModal} handleShow={handleShow} />
		</>
	);
};

export default AppPartners;

import axios from "axios";
import { EnvVariables } from "../env/Env";

const { REACT_APP_URL } = process.env
const { REACT_APP_URL: API_URL } = EnvVariables;

let baseURL;

baseURL = REACT_APP_URL || API_URL; 

const axiosInstance = axios.create({
  baseURL
});


export default axiosInstance;

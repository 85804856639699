import React, { useState, useEffect } from "react";
import CookieConsent from "react-cookie-consent";
import { Link } from "react-router-dom";

const Cookie = () => {
  const [cookieShow, setCookieShow] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setCookieShow(true);
    }, 3000);
  }, []);

  return (
    <div style={{ display: cookieShow ? "block" : "none" }}>
      <CookieConsent
        expires={365}
        sameSite="strict"
        location="bottom"
        buttonText="Accept"
        cookieName="edutech-google-analytics"
        containerClasses="cookie-container"
        buttonClasses="cookie-btn"
        buttonStyle={{
          backgroundColor: "rgb(15, 66, 164)",
          borderRadius: "4px",
          color: "#ffffff",
          fontSize: "14px",
          fontWeight: "bold",
          padding: ".6rem 1rem",
        }}
      >
        <div>
          <p
            style={{
              fontWeight: "bold",
              fontSize: "16px",
              color: "rgb(4, 22, 68)",
            }}
          >
            This website uses cookies
          </p>
          <span style={{ fontSize: "14px" }}>
            These cookies are used to collect information about how you interact
            with our website and allow us to remember you. We use this
            information in order to improve and customize your browsing
            experience and for analytics and metrics. For information about our
            cookies, see our{" "}
            <Link
              to="/cookie-policy"
              style={{ color: "inherit", textDecoration: "underline" }}
            >
              Cookie policy
            </Link>
            . Read about our Privacy policy{" "}
            <Link
              to="/privacy-policy"
              style={{ color: "inherit", textDecoration: "underline" }}
            >
              here.
            </Link>
          </span>
        </div>
      </CookieConsent>
    </div>
  );
};

export default Cookie;

import React, { FC } from "react";
import heroImage from "../assets/img/heropic.jpg";
import programImage from "../assets/img/program.jpg";
import flexibaleImage from "../assets/img/flexibale.jpg";
import graduateImage from "../assets/img/graduate.jpg";
import fastTrack from "../assets/img/student-working-from-home.jpg";

const AbuCampaign: FC = () => {
  return (
    <main>
      <section className="container" style={{ marginTop: "100px" }}>
        <div className="row justify-content text-center">
          <div
            style={{ marginTop: "40px" }}
            className="col-12 col-lg-6 order-last order-lg-1"
          >
            <h1 style={{ fontSize: "23px" }}>
              Create the future you desire with a degree from Ahmadu Bello
              University Distance Learning Center
            </h1>
            <p style={{ fontSize: "12px" }}>
              Here is your chance to earn a degree, no matter where you are,
              from NUC accredited Ahmadu Bello University Distance Learning
              Center (ABU DLC).
            </p>
            <p style={{ fontSize: "12px" }}>
              Powered by EduTech in partnership with ABU DLC, the highly ranked
              programmes deliver the knowledge and skills you need to advance
              your career whenever and wherever it’s convenient for you.
            </p>
            <a href="https://apply.abudlc.edu.ng/">
              <button type="button" className="mt-2 btn btn-dark">
                APPLY NOW
              </button>
            </a>
          </div>
          <div className="col-12 col-lg-6 order-sm-frist order-lg-12">
            <img
              src={heroImage}
              style={{ height: "100%", width: "100%" }}
              alt="hero image"
            />
          </div>
        </div>
      </section>
      <div className="text-center container" style={{ paddingTop: "60px" }}>
        <h1 style={{ fontSize: "30px" }}>Programs</h1>
        <p style={{ fontSize: "12px" }}>
          With EduTech, you can choose from a range of programmes ranging from
          undergraduate to postgraduate programmes offered by Ahmadu Bello
          University DLC:
        </p>
      </div>
      <section
        style={{
          backgroundColor: "#D7DCE0",
          marginTop: "50px",
          paddingTop: "50px",
          paddingBottom: "50px",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6">
              <img
                src={programImage}
                style={{ height: "100%", width: "100%" }}
                alt="hero image"
              />
            </div>
            <div className="col-12 col-lg-6">
              <div className="text-center">
                <h1 style={{ fontSize: "20px", marginTop: "20px" }}>
                  Undergraduate Programmes{" "}
                </h1>
                <p style={{ fontSize: "12px" }}>
                  BSc. Business Administration, BSc. Public Administration, BSc.
                  Economics, BNSc. Nursing Science, BSc. Political Science, BSc.
                  International Studies, BSc. Sociology, BSc. Accounting, BSc.
                  Computer Science.
                </p>
              </div>
              <div className="text-center">
                <h1 style={{ fontSize: "20px", marginTop: "20px" }}>
                  Postgraduate Programmes
                </h1>
                <p style={{ fontSize: "12px" }}>
                  Master in Law Enforcement and Criminal Justice (MLCJ), Master
                  in Information Management (MIM), Master in Public
                  Administration (MPA), Postgraduate Diploma in Education
                  (PGDE), Postgraduate Diploma in Management (PGDM), Master in
                  International Affairs and Diplomacy (MIAD) and Prevention
                  <br />
                  Master in Business Administration (MBA) (with 3
                  Specializations): MBA - Finance and Investment Specialization,
                  MBA - Human Resources Specialization, MBA - Marketing
                  Specialization , MBA - Hajj Operations Specialization
                  <br />
                  Master in Public Health (MPH) (with 3 Specializations): MPH –
                  Epidemiology, MPH - Environmental and Occupational Health, MPH
                  - Health Promotion and Prevention
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        style={{
          marginTop: "50px",
        }}
        className="container"
      >
        <div
          className="text-center container"
          style={{ paddingTop: "60px", paddingBottom: "30px" }}
        >
          <h1 style={{ fontSize: "30px" }}>Why ABU DLC? </h1>
        </div>
        <div className="row">
          <div className="col-12 col-lg-4" style={{ marginBottom: "50px" }}>
            <div style={{ height: "100%" }} className="card border-0">
              <img src={flexibaleImage} className="card-img-top" alt="..." />
              <div className="card-body">
                <h5 className="card-title">Flexible Learning</h5>
                <p style={{ fontSize: "12px" }} className="card-text">
                  With our online learning platform, you get to study at your
                  pace, whenever and wherever it’s convenient for you.
                </p>
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-4" style={{ marginBottom: "50px" }}>
            <div style={{ height: "100%" }} className="card border-0">
              <img src={graduateImage} className="card-img-top" alt="..." />
              <div className="card-body">
                <h5 className="card-title">Same degree as on-campus</h5>
                <p style={{ fontSize: "12px" }} className="card-text">
                  You earn the same degree as those who studied on campus. No
                  differentiation in degree.
                </p>
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-4" style={{ marginBottom: "50px" }}>
            <div style={{ height: "100%" }} className="card border-0">
              <img src={fastTrack} className="card-img-top" alt="..." />
              <div className="card-body">
                <h5 className="card-title">Fast track learning</h5>
                <p style={{ fontSize: "12px" }} className="card-text">
                  You can fast track your study with us as we operate a
                  3-semester window in an academic year. This way, you get to
                  complete your degree in time.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center mb-5" style={{ marginTop: "30px" }}>
          <h4>Ready to take your life and career to the next level</h4>
          <p style={{ fontSize: "12px" }}>
            Don’t take our words for it, here’s what those who have experienced
            us have to say: <br />
            “There's a lot of competition out there but with the ABUDLC MBA, I
            have been able to focus on my career while earning my degree because
            of its flexibility; I don't think any other University in Nigeria
            can top that.” _ Sola Fadahunsi [MBA Student]{" "}
          </p>
          <a href="https://apply.abudlc.edu.ng/">
            <button type="button" className="mt-2 btn btn-dark mt-5 mb-5">
              APPLY NOW
            </button>
          </a>
        </div>
        <p style={{ fontSize: "12px" }}>
          EduTech is a technology company focused on bridging the gap between
          demand for and access to quality education by partnering with local
          and international institutions to make their programs accessible to
          students. We are able to achieve this through our e-learning platform,
          study abroad options and student advisory services for prospects to
          these institutions.{" "}
        </p>
        <hr/>
      </section>
    </main>
  );
};

export default AbuCampaign;
